import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAuthenticator } from '@aws-amplify/ui-react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import colors from 'resources/theme/colors.module.scss';
import { CustomDrawer } from './drawer/CustomDrawer';
import { Header } from './header/Header';
import styles from './MainLayout.module.scss';
export const MainLayout = () => {
    const { authStatus } = useAuthenticator((context) => [context.authStatus]);
    const isAuth = authStatus === 'authenticated';
    const [openDrawer, setOpen] = useState(false);
    return (_jsx(Box, Object.assign({ sx: {
            display: 'flex',
            justifyContent: 'center',
            minHeight: '100vh',
            backgroundColor: colors.lightGray,
        } }, { children: _jsxs(Box, Object.assign({ sx: {
                maxWidth: '500px',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                backgroundColor: colors.lightGray,
            } }, { children: [_jsx(CssBaseline, {}), _jsx(Header, { isAuth: isAuth }), _jsx("main", Object.assign({ className: styles['main'] }, { children: _jsx(Outlet, {}) })), isAuth ? _jsx(CustomDrawer, { open: openDrawer, onClose: setOpen }) : null] })) })));
};
