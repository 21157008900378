import { jsx as _jsx } from "react/jsx-runtime";
import { Suspense, lazy } from 'react';
import { FullPageLoader } from 'components/ui/page-loader/FullPageLoader';
export const lazyLoad = (importFunc, namedExport) => {
    const promise = importFunc();
    const LazyComponent = lazy(() => {
        return namedExport
            ? promise.then((module) => ({ default: module[namedExport] }))
            : promise;
    });
    return (props) => {
        return (_jsx(Suspense, Object.assign({ fallback: _jsx(FullPageLoader, {}) }, { children: _jsx(LazyComponent, Object.assign({}, props)) })));
    };
};
