import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Link, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { CustomButton } from 'components/ui/button/CustomButton';
import { useMediaSize } from 'hooks/useMediaSize';
import colors from 'resources/theme/colors.module.scss';
import styles from './CookieBanner.module.scss';
export const CookieBanner = () => {
    const isTablet = useMediaSize('tablet');
    const { t, i18n: { language }, } = useTranslation();
    const policeHref = language === 'sv'
        ? 'https://www.onebasket.se/integritetspolicy'
        : 'https://www.onebasket.se/privacy-policy';
    const [showBanner, setShowBanner] = useState(false);
    useEffect(() => {
        const cookiesAccepted = document.cookie
            .split(';')
            .some((item) => item.trim().startsWith('cookies_accepted='));
        if (!cookiesAccepted) {
            setShowBanner(true);
        }
    }, []);
    const setCookie = (name, value, days) => {
        let expires = '';
        if (days) {
            const date = new Date();
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
            expires = `; expires=${date.toUTCString()}`;
        }
        document.cookie = `${name}=${value || ''}${expires}; path=/`;
    };
    const handleAcceptAll = () => {
        setCookie('cookies_accepted', 'true', 365);
        setCookie('tracking_accepted', 'true', 365);
        setCookie('advertising_accepted', 'true', 365);
        setShowBanner(false);
    };
    const handleRejectAll = () => {
        setCookie('cookies_accepted', 'true', 365);
        setCookie('tracking_accepted', 'false', 365);
        setCookie('advertising_accepted', 'false', 365);
        setShowBanner(false);
    };
    if (!showBanner) {
        return null;
    }
    return (_jsx(Box, Object.assign({ className: styles['container'] }, { children: _jsxs(Box, Object.assign({ display: "flex", alignItems: "center", justifyContent: "space-between", gap: "12px", flexWrap: isTablet ? 'wrap' : 'nowrap' }, { children: [_jsxs(Box, Object.assign({ textAlign: "start" }, { children: [_jsx(Typography, Object.assign({ variant: "screen" }, { children: t('cookies_title') })), _jsx(Typography, Object.assign({ variant: "default" }, { children: _jsx(Trans, { i18nKey: "cookie_banner", components: {
                                    policy: (_jsx(Typography, { variant: "default_bold", component: Link, href: policeHref, target: "_blank", rel: "noopener noreferrer", color: colors.darkPurple })),
                                } }) }))] })), _jsxs(Box, Object.assign({ display: "flex", gap: "12px", flex: "1", justifyContent: "flex-end" }, { children: [_jsx(CustomButton, { sx: { width: 'unset', height: '40px' }, onClick: handleRejectAll, title: "reject_all", variant: "outlined" }), _jsx(CustomButton, { sx: { width: 'unset', height: '40px' }, onClick: handleAcceptAll, title: "accept_all" })] }))] })) })));
};
