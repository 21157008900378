import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import colors from 'resources/theme/colors.module.scss';
const style = {
    p: '24px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 'min(450px , 95vw)',
    width: '95%',
    bgcolor: colors.white,
    borderRadius: '24px',
    boxShadow: '0px 2px 20px 0px #00000026',
    textAlign: 'center',
};
export const CustomModal = ({ open, setOpen, description, title, content, }) => {
    return (_jsx(Modal, Object.assign({ sx: {
            '& .MuiModal-backdrop': {
                opacity: '0.3 !important',
            },
        }, open: open, onClose: setOpen, "aria-labelledby": "modal-modal-title", "aria-describedby": "modal-modal-description" }, { children: _jsxs(Box, Object.assign({ sx: style }, { children: [_jsxs(Box, Object.assign({ position: "relative" }, { children: [_jsx(IconButton, Object.assign({ sx: { position: 'absolute', top: '-20px', right: '-20px' }, onClick: setOpen }, { children: _jsx(CloseIcon, {}) })), _jsx(Typography, Object.assign({ id: "modal-modal-title", variant: "screen", component: "h2" }, { children: title }))] })), _jsx(Typography, Object.assign({ variant: "default", id: "modal-modal-description", sx: { m: '12px 0' } }, { children: description })), content && content] })) })));
};
