var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState, useEffect } from 'react';
import { FullPageLoader } from 'components/ui/page-loader/FullPageLoader';
import { BrowserMessages } from 'constants/messages';
import { getExtensionId } from 'utils/localStorage';
export const CheckExtentionAuthWrapper = ({ children }) => {
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchDataFromExtension = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const extensionId = getExtensionId();
                const response = yield new Promise((resolve, reject) => {
                    chrome.runtime.sendMessage(extensionId, { action: BrowserMessages.getAuthDataFromExtension }, (response) => {
                        if (chrome.runtime.lastError) {
                            reject(chrome.runtime.lastError);
                        }
                        else {
                            resolve(response);
                        }
                    });
                });
                if (response.data) {
                    Object.entries(response.data).map(([key, value]) => __awaiter(void 0, void 0, void 0, function* () {
                        if (typeof value === 'string') {
                            localStorage.setItem(key, value);
                        }
                        else {
                            localStorage.setItem(key, JSON.stringify(value));
                        }
                    }));
                }
            }
            catch (error) {
                console.error('Error during extension communication:', error);
            }
            finally {
                setLoading(false);
            }
        });
        fetchDataFromExtension();
    }, []);
    if (loading) {
        return _jsx(FullPageLoader, {});
    }
    return children;
};
