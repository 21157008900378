import { createSlice } from '@reduxjs/toolkit';
import { clearStore } from './action/clearStore';
const initialState = {
    lists: {},
    quickSaves: null,
    deleted: null,
    listDetails: [],
    currentProduct: null,
};
const products = createSlice({
    name: 'products',
    initialState,
    reducers: {
        saveCurrentProduct: (state, action) => {
            state.currentProduct = action.payload;
        },
        saveWishLists: (state, action) => {
            state.lists = action.payload;
        },
        addWishListDetails: (state, action) => {
            state.listDetails = [...state.listDetails, ...action.payload];
        },
        saveWishListDetails: (state, action) => {
            state.listDetails = action.payload;
        },
        saveToQuickSaves: (state, action) => {
            state.quickSaves = action.payload;
        },
        saveToDeleted: (state, action) => {
            state.deleted = action.payload;
        },
        removeItemFromList: (state, action) => {
            const { listId, itemIds } = action.payload;
            const filtered = state.lists[listId].filter(({ id }) => !itemIds.includes(id));
            state.lists = Object.assign(Object.assign({}, state.lists), { [listId]: filtered });
        },
        removeFromQuickSaves: (state, action) => {
            if (state.quickSaves) {
                const filtered = state.quickSaves.filter(({ id }) => id !== action.payload);
                state.quickSaves = filtered;
            }
        },
        removeFromDeleted: (state, action) => {
            if (state.deleted) {
                const filtered = state.deleted.filter(({ id }) => id !== action.payload);
                state.deleted = filtered;
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(clearStore, () => initialState);
    },
});
export const { saveWishLists, removeItemFromList, saveToQuickSaves, saveToDeleted, removeFromDeleted, removeFromQuickSaves, addWishListDetails, saveWishListDetails, saveCurrentProduct, } = products.actions;
export default products.reducer;
