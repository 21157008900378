var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormControl, InputLabel, MenuItem, OutlinedInput, Select, } from '@mui/material';
import React, { startTransition } from 'react';
import { useTranslation } from 'react-i18next';
import { supportedLngs } from 'i18n';
import colors from 'resources/theme/colors.module.scss';
import { BrowserMessages } from '../../constants/messages';
import { sendMessageToExtension } from '../../utils/messaging';
export const ChangeLng = (_a) => {
    var { placeholder, boxSx } = _a, props = __rest(_a, ["placeholder", "boxSx"]);
    const { i18n, t } = useTranslation(undefined, { keyPrefix: 'placeholder' });
    const handleChangeLng = (lng) => {
        startTransition(() => {
            i18n
                .changeLanguage(lng)
                .then((value) => sendMessageToExtension(BrowserMessages.changeLang, lng));
        });
    };
    return (_jsxs(FormControl, Object.assign({ sx: boxSx }, { children: [placeholder && (_jsx(InputLabel, Object.assign({ sx: labelSX, id: "language-switcher-label" }, { children: t(placeholder) }))), _jsx(Select, Object.assign({ labelId: "language-switcher-label", id: "language-switcher", displayEmpty: true, input: _jsx(OutlinedInput, { label: placeholder }), value: i18n.language, onChange: (e) => handleChangeLng(e.target.value) }, props, { children: supportedLngs.map((lng) => (_jsx(MenuItem, Object.assign({ value: lng }, { children: lng.toUpperCase() }), lng))) }))] })));
};
const labelSX = ({ typography }) => (Object.assign(Object.assign({}, typography.input), { color: colors.darkPurple, top: '-4px', '&.Mui-focused': {
        color: colors['darkPurple'],
        transform: 'translate(14px, -6px) scale(0.85)',
    }, '&.MuiInputLabel-shrink': {
        color: colors['darkPurple'],
        transform: 'translate(14px, -6px) scale(0.85)',
    } }));
