import { signOut } from 'aws-amplify/auth';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
export const useAmplifySignOut = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    return useMutation(signOut, {
        onSuccess: () => {
            enqueueSnackbar({ variant: 'success', message: t('notify.sign_out') });
        },
        onError: (err) => {
            enqueueSnackbar({ message: err.message, variant: 'warning' });
        },
    });
};
