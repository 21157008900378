import { jsx as _jsx } from "react/jsx-runtime";
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Navigate, useLocation } from 'react-router-dom';
import { FullPageLoader } from 'components/ui/page-loader/FullPageLoader';
import { RouteKeys } from 'constants/routes';
export const RouteWrapper = ({ children, type }) => {
    const location = useLocation();
    const { authStatus } = useAuthenticator((context) => [context.authStatus]);
    const isAuth = authStatus === 'authenticated';
    const isNotAuth = authStatus === 'unauthenticated';
    const isLoading = authStatus === 'configuring';
    if (isLoading) {
        return _jsx(FullPageLoader, {});
    }
    if (type === 'protected' && isNotAuth) {
        return _jsx(Navigate, { to: RouteKeys.login, state: { from: location }, replace: true });
    }
    if (type === 'public' && isAuth) {
        return _jsx(Navigate, { to: RouteKeys.dashboard, replace: true });
    }
    return children;
};
