import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, IconButton, Typography } from '@mui/material';
import { SnackbarProvider, closeSnackbar, SnackbarContent, useSnackbar, } from 'notistack';
import React, { useMemo } from 'react';
import { useMediaSize } from 'hooks/useMediaSize';
import colors from 'resources/theme/colors.module.scss';
const CustomContentBox = ({ icon, backgroundColor, color, id, message, }) => {
    return (_jsxs(Box, Object.assign({ padding: "20px 16px", borderRadius: "6px", minWidth: "300px", display: "flex", alignItems: "center", sx: { backgroundColor, overflow: 'hidden' } }, { children: [_jsx("span", Object.assign({ style: { fontSize: '16px' } }, { children: icon })), _jsx(Typography, Object.assign({ m: "0 16px 0 8px", variant: "body1", maxWidth: "350px", color: color }, { children: message })), _jsx(Box, Object.assign({ marginLeft: "auto", height: "16px", width: "16px", onClick: () => closeSnackbar(id), sx: { cursor: 'pointer', display: 'flex', alignItems: 'center' } }, { children: _jsx("span", Object.assign({ style: { fontSize: '16px' } }, { children: "\u2716\uFE0F" })) }))] })));
};
const SnackbarSuccess = React.forwardRef((props, ref) => {
    return (_jsx(SnackbarContent, Object.assign({ ref: ref, role: "alert" }, { children: _jsx(CustomContentBox, Object.assign({}, props, { icon: "\u2705", backgroundColor: "green", color: colors.white })) })));
});
const SnackbarWarning = React.forwardRef((props, ref) => {
    return (_jsx(SnackbarContent, Object.assign({ ref: ref, role: "alert" }, { children: _jsx(CustomContentBox, Object.assign({}, props, { icon: "\u26A0\uFE0F", backgroundColor: "#FFDED7", color: "#9D1C00" })) })));
});
const CloseButton = ({ id }) => {
    const { closeSnackbar } = useSnackbar();
    return (_jsx(IconButton, Object.assign({ sx: {
            width: '36px',
            height: '36px',
            color: colors.white,
            fontSize: '20px',
        }, "aria-label": "close", onClick: () => closeSnackbar(id) }, { children: "\u2A09" })));
};
export const CustomSnackbarProvider = ({ children, }) => {
    const isMobile = useMediaSize('mobile');
    const anchorOrigin = useMemo(() => isMobile
        ? { vertical: 'bottom', horizontal: 'center' }
        : { vertical: 'top', horizontal: 'right' }, [isMobile]);
    return (_jsx(SnackbarProvider, Object.assign({ autoHideDuration: 7000, anchorOrigin: anchorOrigin, action: (key) => _jsx(CloseButton, { id: key }) }, { children: children })));
};
