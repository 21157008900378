export var RouteKeys;
(function (RouteKeys) {
    RouteKeys["home"] = "/";
    RouteKeys["login"] = "/login";
    RouteKeys["signUp"] = "/sign-up";
    RouteKeys["forgot"] = "/forgot-password";
    RouteKeys["dashboard"] = "/dashboard";
    RouteKeys["trash"] = "/trash";
    RouteKeys["add-product"] = "/add-product";
    RouteKeys["profile"] = "/profile";
    RouteKeys["wish-list"] = "/wish-list";
})(RouteKeys || (RouteKeys = {}));
