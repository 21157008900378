import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import LogoutIcon from '@mui/icons-material/Logout';
import { AppBar, Box, IconButton, Toolbar } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { CustomModal } from 'components/modal/CustomModal';
import { CustomButton } from 'components/ui/button/CustomButton';
import { BrowserMessages } from 'constants/messages';
import colors from 'resources/theme/colors.module.scss';
import { useAmplifySignOut } from 'services/SignOut';
import { getExtensionId } from 'utils/localStorage';
import { drawerNav } from './drawer';
export const CustomDrawer = ({ onClose }) => {
    const { t } = useTranslation();
    const { mutate, isLoading } = useAmplifySignOut();
    const [openModal, setOpenModal] = useState(false);
    const handleLogOut = () => {
        !isLoading &&
            mutate({ global: true }, {
                onSuccess: () => {
                    const extensionId = getExtensionId();
                    chrome.runtime.sendMessage(extensionId, {
                        action: BrowserMessages.logOutFromExtension,
                    });
                    onClose(false);
                },
            });
    };
    const handleClose = () => {
        setOpenModal(false);
    };
    return (_jsxs(_Fragment, { children: [openModal && (_jsx(CustomModal, { open: openModal, setOpen: handleClose, title: t('log_out_modal_title'), description: t('log_out_modal_sub_title'), content: _jsxs(Box, Object.assign({ display: "flex", gap: "12px" }, { children: [_jsx(CustomButton, { disabled: isLoading, sx: { mt: '12px' }, title: 'close', onClick: handleClose, variant: "outlined" }), _jsx(CustomButton, { disabled: isLoading, sx: { mt: '12px' }, title: "sign_out", onClick: handleLogOut })] })) })), _jsx(AppBar, Object.assign({ component: "nav", position: "sticky", sx: {
                    height: '80px',
                    top: 'auto',
                    bottom: 0,
                    backgroundColor: colors.darkPurple,
                    borderTopRightRadius: '32px',
                    borderTopLeftRadius: '32px',
                } }, { children: _jsxs(Toolbar, Object.assign({ sx: {
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'space-around',
                    } }, { children: [drawerNav.map((item) => {
                            const { title, to, Icon } = item;
                            return (_jsx(NavLink, Object.assign({ to: to, style: {
                                    borderRadius: '4px',
                                } }, { children: ({ isActive }) => (_jsx(IconButton, Object.assign({ "aria-label": title, sx: {
                                        borderRadius: '16px',
                                        backgroundColor: isActive
                                            ? colors.mediumPurple
                                            : colors.darkPurple,
                                        '&:hover': {
                                            background: colors.mediumPurple,
                                        },
                                        '&:active': {
                                            background: colors.mediumPurple,
                                        },
                                    } }, { children: _jsx(Icon, { color: colors.lightPurple, width: "32px", height: "32px" }) }))) }), title));
                        }), _jsx(IconButton, Object.assign({ "aria-label": "log out", onClick: () => setOpenModal(true), sx: {
                                borderRadius: '16px',
                                backgroundColor: colors.darkPurple,
                                '&:hover': {
                                    background: colors.mediumPurple,
                                },
                                '&:active': {
                                    background: colors.mediumPurple,
                                },
                            } }, { children: _jsx(LogoutIcon, { sx: { color: colors.lightPurple, width: '32px', height: '32px' } }) }))] })) }))] }));
};
