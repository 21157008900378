import { jsx as _jsx } from "react/jsx-runtime";
import { Box, CircularProgress } from '@mui/material';
import colors from 'resources/theme/colors.module.scss';
export const FullPageLoader = () => {
    return (_jsx(Box, Object.assign({ sx: {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden',
            background: 'white',
            zIndex: 9999,
        } }, { children: _jsx(CircularProgress, { size: 100, sx: { color: colors.mediumPurple } }) })));
};
