import { RouteKeys } from 'constants/routes';
import { AddIcon } from 'resources/svg/AddIcon';
import { HomeIcon } from 'resources/svg/HomeIcon';
import { ListIcon } from 'resources/svg/ListIcon';
import { ProfileIcon } from 'resources/svg/ProfileIcon';
import { TrashIcon } from 'resources/svg/TrashIcon';
export const drawerNav = [
    {
        title: 'Home',
        to: RouteKeys['dashboard'],
        Icon: HomeIcon,
    },
    {
        title: 'Wish lists',
        to: RouteKeys['wish-list'],
        Icon: ListIcon,
    },
    {
        title: 'Quick saves',
        to: RouteKeys['add-product'],
        Icon: AddIcon,
    },
    {
        title: 'Profile',
        to: RouteKeys.profile,
        Icon: ProfileIcon,
    },
    {
        title: 'Trash',
        to: RouteKeys.trash,
        Icon: TrashIcon,
    },
];
