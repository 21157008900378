import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Navigate, Route, Routes } from 'react-router-dom';
import { lazyLoad } from 'components/lazy/lazyLoad';
import { MainLayout } from 'components/main-layout/MainLayout';
import { RouteWrapper } from 'components/route-wrapper/RouteWrapper';
import { RouteKeys } from 'constants/routes';
const Login = lazyLoad(() => import('views/login/Login'), 'Login');
const SignUp = lazyLoad(() => import('views/sign-up/SignUp'), 'SignUp');
const ForgotPassword = lazyLoad(() => import('views/forgot-password/ForgotPassword'), 'ForgotPassword');
const Dashboard = lazyLoad(() => import('views/dashboard/Dashboard'), 'Dashboard');
const Profile = lazyLoad(() => import('views/profile/Profile'), 'Profile');
const WishLists = lazyLoad(() => import('views/wish-lists/WishLists'), 'WishLists');
const WishListDetails = lazyLoad(() => import('views/wish-lists/wish-list-details/WishListDetails'), 'WishListDetails');
const AddProduct = lazyLoad(() => import('views/add-product/AddProduct'), 'AddProduct');
const Trash = lazyLoad(() => import('views/trash/Trash'), 'Trash');
export const App = () => {
    return (_jsx(Routes, { children: _jsxs(Route, Object.assign({ element: _jsx(MainLayout, {}) }, { children: [_jsx(Route, { path: RouteKeys.home, element: _jsx(Navigate, { to: RouteKeys.login, replace: true }) }), _jsx(Route, { path: RouteKeys.login, element: _jsx(RouteWrapper, Object.assign({ type: "public" }, { children: _jsx(Login, {}) })) }), _jsx(Route, { path: RouteKeys.signUp, element: _jsx(RouteWrapper, Object.assign({ type: "public" }, { children: _jsx(SignUp, {}) })) }), _jsx(Route, { path: RouteKeys.forgot, element: _jsx(RouteWrapper, Object.assign({ type: "public" }, { children: _jsx(ForgotPassword, {}) })) }), _jsx(Route, { path: RouteKeys.dashboard, element: _jsx(RouteWrapper, Object.assign({ type: "protected" }, { children: _jsx(Dashboard, {}) })) }), _jsx(Route, { path: RouteKeys.trash, element: _jsx(RouteWrapper, Object.assign({ type: "protected" }, { children: _jsx(Trash, {}) })) }), _jsx(Route, { path: RouteKeys['add-product'], element: _jsx(RouteWrapper, Object.assign({ type: "protected" }, { children: _jsx(AddProduct, {}) })) }), _jsx(Route, { path: RouteKeys.profile, element: _jsx(RouteWrapper, Object.assign({ type: "protected" }, { children: _jsx(Profile, {}) })) }), _jsx(Route, { path: RouteKeys['wish-list'], element: _jsx(RouteWrapper, Object.assign({ type: "protected" }, { children: _jsx(WishLists, {}) })) }), _jsx(Route, { path: `${RouteKeys['wish-list']}/:listName`, element: _jsx(RouteWrapper, Object.assign({ type: "protected" }, { children: _jsx(WishListDetails, {}) })) }), _jsx(Route, { path: "/*", element: _jsx(Navigate, { to: RouteKeys.login, replace: true }) })] })) }));
};
