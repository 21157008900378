import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Authenticator } from '@aws-amplify/ui-react';
import { ThemeProvider } from '@mui/material';
import awsExports from 'aws/src/aws-exports';
import { Amplify } from 'aws-amplify';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { App } from 'App';
import { CookieBanner } from 'components/cookie-banner/CookieBanner';
import { CustomSnackbarProvider } from 'components/snackbar-provider/CustomSnackbarProvider';
import i18n from 'i18n';
import { theme } from 'resources/theme/theme';
import { store } from 'store';
import { CheckExtentionAuthWrapper } from './components/check-extension-auth-wrapper/CheckExtentionAuthWrapper';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import 'resources/styles/index.css';
const queryClient = new QueryClient();
Amplify.configure(awsExports);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(_jsx(React.StrictMode, { children: _jsx(CheckExtentionAuthWrapper, { children: _jsx(Authenticator.Provider, { children: _jsx(Provider, Object.assign({ store: store }, { children: _jsx(I18nextProvider, Object.assign({ i18n: i18n }, { children: _jsx(QueryClientProvider, Object.assign({ client: queryClient }, { children: _jsx(ThemeProvider, Object.assign({ theme: theme }, { children: _jsx(BrowserRouter, { children: _jsxs(CustomSnackbarProvider, { children: [_jsx(App, {}), _jsx(CookieBanner, {})] }) }) })) })) })) })) }) }) }));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorkerRegistration.register();
