export const typography = {
    fontFamily: [
        'Noto Sans',
        '-apple-system',
        'BlinkMacSystemFont',
        'Segoe UI',
        'Roboto',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        'sans-serif',
    ].join(','),
    button: {
        fontSize: '12px',
        fontWeight: 700,
        textTransform: 'lowercase',
        lineHeight: '16px',
        letterSpacing: 0,
        '& span': {
            ['&::first-letter']: {
                textTransform: 'uppercase',
            },
        },
    },
    screen: {
        fontSize: '24px',
        fontWeight: 700,
        lineHeight: '36px',
        letterSpacing: '-0.01em',
    },
    section: {
        fontSize: '20px',
        fontWeight: 700,
        lineHeight: '30px',
    },
    link: {
        fontSize: '12px',
        fontWeight: 700,
        lineHeight: '16px',
    },
    default: {
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '24px',
    },
    default_bold: {
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '24px',
    },
    small: {
        fontSize: '10px',
        fontWeight: 400,
        lineHeight: '14px',
    },
    small_bold: {
        fontSize: '10px',
        fontWeight: 700,
        lineHeight: '14px',
    },
    validationError: {
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '16px',
    },
    input: {
        fontSize: '15px',
        fontStyle: 'italic',
        fontWeight: 300,
        lineHeight: '23px',
    },
};
