import { createTheme } from '@mui/material';
import colors from './colors.module.scss';
import { typography } from './typography';
export const theme = createTheme({
    spacing: 2,
    palette: {
        mode: 'light',
        themeSwitch: {
            dark: colors.black,
            light: colors.black,
        },
        backgroundColor: {
            black: colors.black,
            white: colors.white,
        },
        background: {
            default: colors.white,
        },
    },
    typography,
    components: {
        MuiTypography: {
            defaultProps: {
                variantMapping: {
                    screen: 'h1',
                    section: 'h2',
                    default: 'p',
                    default_bold: 'p',
                    small: 'p',
                    small_bold: 'p',
                    validationError: 'p',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                outlinedPrimary: {
                    backgroundColor: 'transparent',
                    border: `1px solid ${colors.lightPurple}`,
                    color: colors.darkPurple,
                    boxShadow: 'none',
                    transition: 'none',
                    borderRadius: '24px',
                    '&:hover': {
                        border: `1px solid ${colors.lightPurple}`,
                        background: colors.lightPurple,
                        color: colors.darkPurple,
                        boxShadow: 'none',
                        transition: 'none',
                    },
                    '&:active': {
                        background: colors.lightPurple,
                        color: colors.darkPurple,
                        boxShadow: 'none',
                        transition: 'none',
                        '& .MuiTouchRipple-root': {
                            opacity: 0.3,
                        },
                    },
                    '&:disabled': {
                        backgroundColor: colors.lightLavender,
                        span: {
                            color: colors.lightPurple,
                            svg: {
                                path: {
                                    fill: colors.lightPurple,
                                },
                            },
                        },
                    },
                    '& .Mui-focusVisible': {
                        backgroundColor: 'yellow',
                        opacity: 0.5,
                    },
                },
                contained: {
                    backgroundColor: colors.darkPurple,
                    color: colors.lightLavender,
                    boxShadow: 'none',
                    transition: 'none',
                    borderRadius: '24px',
                    '&:hover': {
                        background: colors.darkPurple,
                        boxShadow: 'none',
                        transition: 'none',
                    },
                    '&:active': {
                        backgroundColor: colors.darkPurple,
                        boxShadow: 'none',
                        '& .MuiTouchRipple-root': {
                            opacity: 0.3,
                        },
                    },
                    '&:disabled': {
                        backgroundColor: colors.lightPurple,
                        span: {
                            color: colors.lightLavender,
                            svg: {
                                path: {
                                    fill: colors.lightLavender,
                                },
                            },
                        },
                    },
                    '&.MuiButton-sizeSmall': {
                        width: 'fit-content',
                        minWidth: 'fit-content',
                        height: 'unset',
                        padding: '10px 12px',
                        fontSize: '12px',
                        lineHeight: '10.8px',
                        whiteSpace: 'nowrap',
                        borderRadius: '24px',
                    },
                },
                textPrimary: {
                    backgroundColor: 'transparent',
                    color: colors.grey300,
                    boxShadow: 'none',
                    transition: 'none',
                    '&:hover': {
                        background: '#F6F4F0',
                        boxShadow: 'none',
                        transition: 'none',
                    },
                    '&:active': {
                        background: '#F6F4F0',
                        boxShadow: 'none',
                        transition: 'none',
                        '& .MuiTouchRipple-root': {
                            opacity: 0.3,
                        },
                    },
                    '&.Mui-disabled': {
                        border: 'none',
                        backgroundColor: colors.grey300,
                        color: colors.white,
                    },
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    backgroundColor: 'transparent',
                    color: colors.darkPurple,
                    boxShadow: 'none',
                    transition: 'none',
                    minHeight: '42px',
                    minWidth: '42px',
                    '&:hover': {
                        background: colors.lightLavender,
                        color: colors.darkPurple,
                        boxShadow: 'none',
                        transition: 'none',
                    },
                    '&:active': {
                        background: colors.lightLavender,
                        color: colors.darkPurple,
                        boxShadow: 'none',
                        transition: 'none',
                        '& .MuiTouchRipple-root': {
                            opacity: 0.2,
                        },
                    },
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    textDecoration: 'none',
                    '&:hover': {
                        textDecoration: 'underline',
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                input: ({ theme }) => (Object.assign(Object.assign({}, theme.typography.input), { padding: '13px 14px', color: `${colors.darkPurple} !important` })),
                root: {
                    backgroundColor: colors.white,
                    borderRadius: '24px',
                    fieldset: {
                        border: `1px solid ${colors.lightPurple}`,
                    },
                    '&:not(.Mui-disabled):hover .MuiOutlinedInput-notchedOutline': {
                        border: `2px solid ${colors.mediumPurple}`,
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        border: `2px solid ${colors.mediumPurple}`,
                    },
                    '& fieldset': {
                        borderColor: colors.lightPurple,
                    },
                    '&:hover fieldset': {
                        borderColor: 'yellow',
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: '#6F7E8C',
                    },
                },
            },
        },
    },
});
