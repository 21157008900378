import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AppBar, Box, Toolbar } from '@mui/material';
import { ChangeLng } from 'components/change-lng/ChangeLng';
import { LogoIcon } from 'resources/svg/LogoIcon';
import colors from 'resources/theme/colors.module.scss';
export const Header = ({ isAuth }) => {
    return (_jsx(AppBar, Object.assign({ position: "sticky", sx: {
            backgroundColor: colors.lightGray,
            boxShadow: 'none',
        } }, { children: _jsx(Toolbar, { children: _jsxs(Box, Object.assign({ p: "12px", width: "100%", display: "flex", justifyContent: isAuth ? 'center' : 'flex-end', alignItems: "center" }, { children: [isAuth && _jsx(LogoIcon, { width: "64px", height: "64px" }), !isAuth && (_jsx(Box, { children: _jsx(ChangeLng, {}) }))] })) }) })));
};
