var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import Button from '@mui/material/Button';
import cl from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from './CustomButton.module.scss';
export const CustomButton = (_a) => {
    var { type = 'button', className, title, variant = 'contained', color = 'primary', children, tOptions } = _a, props = __rest(_a, ["type", "className", "title", "variant", "color", "children", "tOptions"]);
    const { t } = useTranslation(undefined, { keyPrefix: 'button' });
    return (_jsx(Button, Object.assign({ type: type, variant: variant, className: cl(styles.button, className), color: color }, props, { children: children ||
            (title && _jsx("span", { children: t(title.toLocaleLowerCase(), tOptions) })) })));
};
